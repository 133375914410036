import React from "react";
import { Row, Col, Select, Checkbox, Divider } from "antd";
import {
  BookingReportSaleFilters,
  BookingReportCsvFormat,
  EnsProductCarrierTid,
  EnsProductCategory,
  ForecastTableType,
  IBookingReportSaleFilters,
  ILiveforecastReportRequest,
} from "common/services/microservices/finance-client";
import { EnsSaleStatusTid } from "common/services/microservices/sales-client";
import { useState, useEffect } from "react";
import { CarrierCheckboxes } from "./CarrierCheckboxes";
import { ProductCheckboxes } from "./ProductCheckboxes";
import { StatusCheckboxes } from "./StatusCheckboxes";

interface FullReportRequestFormRowsProps {
  showForecastFilter: boolean;
  onFiltersChange: (f: ILiveforecastReportRequest, ok: boolean) => void;
}

export function FullReportRequestBuilder(props: FullReportRequestFormRowsProps) {
  const { showForecastFilter, onFiltersChange } = props;
  const [forcastNumber, setForecastNumer] = useState<number>(1);
  const [reportType, setReportType] = useState<BookingReportCsvFormat>(BookingReportCsvFormat.AdminBaseSeparate);
  const [forcastTable, setForcastTable] = useState<ForecastTableType>(ForecastTableType.TripleSeven);
  const [carriers, setCarriers] = useState<EnsProductCarrierTid[]>([]);
  const [products, setProduct] = useState<EnsProductCategory[]>([]);
  const [issueStatus, setIssueStatus] = useState<EnsSaleStatusTid[]>([]);
  const [currentStatus, setCurrentStatus] = useState<EnsSaleStatusTid[]>([]);
  const [filterCarrier, setFilterCarrier] = useState<boolean>(false);
  const [filterProduct, setFilterProduct] = useState<boolean>(false);
  const [filterIssueStatus, setFilterIssueStatus] = useState<boolean>(false);
  const [filterCurrentStatus, setFilterCurrentStatus] = useState<boolean>(false);

  useEffect(() => {
    const filterObject: IBookingReportSaleFilters = {
      carriers: carriers,
      product_categories: products,
      issue_statuses: issueStatus,
      current_statuses: currentStatus,
    };

    const goodCarrier = filterCarrier ? carriers.length > 0 : true;
    const goodProduct = filterProduct ? products.length > 0 : true;
    const goodIssueStatus = filterIssueStatus ? issueStatus.length > 0 : true;
    const goodCurrentStatus = filterCurrentStatus ? currentStatus.length > 0 : true;

    const goodFilters = goodCarrier && goodProduct && goodIssueStatus && goodCurrentStatus;

    onFiltersChange(
      {
        report_type: reportType,
        filters: new BookingReportSaleFilters(filterObject),
        forecast_type_tid: forcastNumber,
        forecast_table: forcastTable,
      },
      goodFilters
    );
  }, [
    filterCarrier,
    filterProduct,
    filterIssueStatus,
    filterCurrentStatus,
    carriers,
    products,
    issueStatus,
    currentStatus,
    reportType,
    forcastNumber,
    forcastTable,
  ]);

  return (
    <>
      <Row align="middle" justify="center" style={{ paddingBottom: "20px" }}>
        <Col span={6}>
          <Col>Table</Col>
          <Select<number>
            style={{ width: "100%" }}
            value={forcastTable}
            options={[
              { value: ForecastTableType.TripleSeven, label: "777" },
              { value: ForecastTableType.Uhas, label: "Uhas" },
            ]}
            onChange={(value) => setForcastTable(value)}
          />
        </Col>
        {showForecastFilter && (
          <Col span={6}>
            <Col>Forecast</Col>
            <Select<number>
              style={{ width: "100%" }}
              placeholder={"Forecast..."}
              value={forcastNumber}
              options={[
                { value: 1 },
                { value: 2 },
                { value: 3 },
                { value: 4 },
                { value: 5 },
                { value: 6 },
                { value: 7 },
                { value: 8 },
                { value: 9 },
              ]}
              onChange={(value) => setForecastNumer(value)}
            />
          </Col>
        )}
        <Col span={6}>
          <Col>Report Format</Col>
          <Select<number>
            style={{ width: "100%" }}
            value={reportType}
            options={[
              { value: BookingReportCsvFormat.AdminBaseSeparate, label: "Admin/Base Separate" },
              { value: BookingReportCsvFormat.AdminBaseTogether, label: "Admin/Base Together" },
            ]}
            onChange={(value) => setReportType(value)}
          />
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ paddingBottom: "20px" }}>
        <Col span={6} style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Checkbox
            value={filterCarrier}
            onChange={(f) => {
              const checked = f.target.checked;
              if (!checked) {
                setCarriers([]);
              }
              setFilterCarrier(f.target.checked);
            }}
          >
            Filter Carriers
          </Checkbox>
          <Divider />
          {filterCarrier && (
            <CarrierCheckboxes
              disabled={!filterCarrier}
              selectedCarriers={carriers}
              onCarriersSelected={(s) => setCarriers(s)}
            />
          )}
        </Col>
        <Col span={6} style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Checkbox
            value={filterProduct}
            onChange={(f) => {
              const checked = f.target.checked;
              if (!checked) {
                setProduct([]);
              }
              setFilterProduct(f.target.checked);
            }}
          >
            Filter Product Categories
          </Checkbox>
          <Divider />
          {filterProduct && (
            <ProductCheckboxes
              disabled={!filterProduct}
              selectedProductCategories={products}
              onProductCategoriesSelected={(s) => setProduct(s)}
            />
          )}
        </Col>
        <Col span={6} style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Checkbox
            value={filterIssueStatus}
            onChange={(f) => {
              const checked = f.target.checked;
              setFilterIssueStatus(checked);
              if (!checked) {
                setIssueStatus([]);
              }
            }}
          >
            Filter Issue Status
          </Checkbox>
          <Divider />
          {filterIssueStatus && (
            <StatusCheckboxes
              disabled={!filterIssueStatus}
              selectedStatuses={issueStatus}
              onStatusesSelected={(s) => setIssueStatus(s)}
            />
          )}
        </Col>{" "}
        <Col span={6} style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Checkbox
            value={filterCurrentStatus}
            onChange={(f) => {
              const checked = f.target.checked;
              setFilterCurrentStatus(checked);
              if (!checked) {
                setCurrentStatus([]);
              }
            }}
          >
            Filter Current Status
          </Checkbox>
          <Divider />
          {filterCurrentStatus && (
            <StatusCheckboxes
              disabled={!filterCurrentStatus}
              selectedStatuses={currentStatus}
              onStatusesSelected={(s) => setCurrentStatus(s)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
