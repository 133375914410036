import React from "react";
import { Row, Col, Select, Divider } from "antd";
import { BookingReportCsvFormat, ITrueUpReportRequest } from "common/services/microservices/finance-client";
import { useState, useEffect } from "react";

interface TrueUpReportRequestBuilderProps {
  onFiltersChange: (f: ITrueUpReportRequest, ok: boolean) => void;
}

export function TrueUpReportRequestBuilder(props: TrueUpReportRequestBuilderProps) {
  const { onFiltersChange } = props;
  const [reportType, setReportType] = useState<BookingReportCsvFormat>(BookingReportCsvFormat.AdminBaseSeparate);

  useEffect(() => {
    onFiltersChange(
      {
        report_type: reportType,
      },
      true
    );
  }, [reportType]);

  return (
    <>
      <Row align="middle" justify="center" style={{}}>
        <Col span={6}>
          <Col>Report Format</Col>
          <Select<number>
            style={{ width: "100%" }}
            value={reportType}
            options={[
              { value: BookingReportCsvFormat.AdminBaseSeparate, label: "Admin/Base Separate" },
              { value: BookingReportCsvFormat.AdminBaseTogether, label: "Admin/Base Together" },
            ]}
            onChange={(value) => setReportType(value)}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
}
