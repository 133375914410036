import React, { useState } from "react";
import { WorkStateType } from "common/services/microservices/basic-client";
import { authenticatedClient } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  ForecastTableType,
  ILiveforecastReportRequest,
  LiveforecastReportRequest,
  RevenueBookingClient,
} from "common/services/microservices/finance-client";
import { Button } from "antd";
import moment from "moment";
import { ProgressDisplayer } from "./ProgressDisplayer";
import { FullReportRequestBuilder } from "./FullReportRequestBuilder";
import { useWorkWaiter } from "common/components/useWorkWaiter";

function FullReportGenerator(props: ILiveforecastReportRequest) {
  const { startWork, isLoading, taskState, taskEvents, progress, downloadResult } = useWorkWaiter();

  async function startReport() {
    const bookingClient = await authenticatedClient(MicroservicesEndpoint.finance, RevenueBookingClient);
    return await bookingClient.startGenerateLiveReportwork(new LiveforecastReportRequest(props));
  }

  return (
    <>
      <Button
        loading={isLoading}
        onClick={() => {
          const sourceName = props.forecast_table === ForecastTableType.TripleSeven ? "777" : "Uhas";
          startWork(
            startReport,
            `RevenueReport-From${sourceName}-${moment().format("YYYYMMDD")}.zip`,
            "Started generating report. This may take some time."
          );
        }}
      >
        Generate New Live Report
      </Button>
      <div className="my-2">
        <ProgressDisplayer progress={progress} taskEvents={taskEvents} state={taskState} />
      </div>
      {taskState === WorkStateType.Success && (
        <Button loading={isLoading} className="ml-3 mt-2 btn-success" onClick={downloadResult}>
          Download Reports
        </Button>
      )}
    </>
  );
}

export function FullReportGeneratorLiveTool() {
  const [request, setRquest] = useState<ILiveforecastReportRequest>();
  const [filtersOk, setFiltersOk] = useState<boolean>();

  return (
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <h3>Live Forecast Report Generator</h3>
      <h5>This report will pull the current forecasts based on the current data in the live tables.</h5>
      <FullReportRequestBuilder
        showForecastFilter
        onFiltersChange={(request, ok) => {
          setRquest(request);
          setFiltersOk(ok);
        }}
      />
      {request && filtersOk && <FullReportGenerator {...request} />}
    </div>
  );
}
