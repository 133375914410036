import React, { useState } from "react";
import { WorkStateType } from "common/services/microservices/basic-client";
import { authenticatedClient } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  ITrueUpReportRequest,
  RevenueBookingClient,
  TrueUpReportRequest,
} from "common/services/microservices/finance-client";
import { Button } from "antd";
import moment from "moment";
import { useWorkWaiter } from "common/components/useWorkWaiter";
import { ProgressDisplayer } from "./ProgressDisplayer";
import { TrueUpReportRequestBuilder } from "./TrueUpReportRequestBuilder";

interface TrueUpReveneBookingReportGeneratorProps {
  request: ITrueUpReportRequest;
}

function TrueUpReveneBookingReportGenerator(props: TrueUpReveneBookingReportGeneratorProps) {
  const { request } = props;
  const { startWork, isLoading, taskState, taskEvents, progress, downloadResult } = useWorkWaiter();

  async function startTrueUpReport() {
    const bookingClient = await authenticatedClient(MicroservicesEndpoint.finance, RevenueBookingClient);
    return await bookingClient.startGenerateTrueUpReportWork(new TrueUpReportRequest(request));
  }

  return (
    <>
      <Button
        loading={isLoading}
        onClick={() =>
          startWork(
            startTrueUpReport,
            `RevenueReport-FromTrueUp-${moment().format("YYYYMMDD")}.zip`,
            "Started generating report. This may take some time."
          )
        }
      >
        Generate New True-Up Revenue Report
      </Button>
      <ProgressDisplayer progress={progress} taskEvents={taskEvents} state={taskState} />
      {taskState === WorkStateType.Success && (
        <Button loading={isLoading} className="ml-3 btn-success" onClick={downloadResult}>
          Download Report
        </Button>
      )}
    </>
  );
}

export function TrueUpReportGeneratorTool() {
  const [request, setRquest] = useState<ITrueUpReportRequest>();

  return (
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <h3>True-up Revenue Booking Report Generator</h3>
      <TrueUpReportRequestBuilder
        onFiltersChange={(request) => {
          setRquest(request);
        }}
      />
      {request && <TrueUpReveneBookingReportGenerator request={request} />}
    </div>
  );
}
